import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { DropdownItem } from "../SearchableDropDown";

const DropDown: React.FC<{
  items: DropdownItem[];
  onChange: (input: string) => void;
  selectedLanguage: string;
  className?: string;
  label: string;
  isReadonly?: boolean;
}> = ({ items, onChange, selectedLanguage, className, label, isReadonly }) => {
  const [dropDownIsOpen, setDropDownIsOpen] = useState(false);
  const [dropDownIsHovered, setDropDownIsHovered] = useState(false);
  const [dropDownIsShown, setDropDownIsShown] = useState(false);

  useEffect(() => {
    !isReadonly && dropDownIsOpen && setDropDownIsShown(true);
    !dropDownIsOpen && !dropDownIsHovered && setDropDownIsShown(false);
  }, [dropDownIsHovered, dropDownIsOpen, isReadonly]);

  const languagesArray = items.map((lang, i) => {
    return (
      <li
        onClick={() => {
          onChange(lang.value as string);
        }}
        className={`px-4 py-[14px] flex ${
          i !== items.length - 1 && "border-b"
        }  border-secondary-300 cursor-pointer text-[13px] font-light text-black2B`}
      >
        <span>{lang.label}</span>
      </li>
    );
  });

  return (
    <div className="flex w-full">
      <div className={`relative w-full ${className}`}>
        <label className="text-sm font-light text-black2B">{label}</label>
        <div
          tabIndex={0}
          onClick={() => setDropDownIsOpen(!dropDownIsOpen)}
          onBlur={() => {
            setDropDownIsOpen(false);
          }}
          className={`relative w-full px-4 py-[9px] rounded-[5px] border cursor-pointer bg-white text-[13px] font-light h-[43px] outline-none hover:border-primary  mt-2 caret-transparent ${
            isReadonly ? "text-gray border-gray" : "text-secondary-300 border-secondary-300 "
          }`}
        >
          {items.find((item) => item.value === selectedLanguage)?.label}
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`absolute right-4 text-secondary-300 duration-300 top-1/2 -translate-y-1/2 ${
              dropDownIsShown ? "rotate-180" : "rotate-0"
            }`}
          />
        </div>
        {dropDownIsShown && (
          <div
            className="absolute w-full   left-0 border border-secondary-300 bg-white translate-y-1 rounded-[5px] z-50"
            onMouseOver={() => {
              setDropDownIsHovered(true);
            }}
            onMouseOut={() => {
              setDropDownIsHovered(false);
            }}
            onTouchStart={() => setDropDownIsHovered(true)}
            onTouchEnd={() => setDropDownIsHovered(false)}
          >
            <ul>{languagesArray}</ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropDown;
