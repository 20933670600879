import { useContext } from "react";
import { faPalette } from "@fortawesome/pro-regular-svg-icons";
import dayjs from "dayjs";

import { ImageValueType, BlockDetailsFieldType, OptionType, LangValuePairType } from "../../utils/types";
import { ManageBlockDetailContext } from "../../store/ManageBlockErrorContext";
import { getInputField } from "./../layout/StylesSidebar";
import Dropdown from "./../inputs/Dropdown";
import DropdownObj from "./../inputs/DropdownObj";
import RichTextInput from "./../inputs/RichTextInput";
import FileInput from "../inputs/FileInput";
import CmsTable, { RowData } from "./../cms_table/CmsTable";
import BlockDetailsSectionContainer from "./../layout/BlockDetailsSectionContainer";
import MultiValues from "./../inputs/MultiValues";
import DateInput from "../inputs/DateInput";
import MultiCheckbox from "../inputs/MultiCheckbox";

const BlockDetailsSection: React.FC<{
  item: BlockDetailsFieldType;
  onChangeValue: (
    slug: string,
    value: string | number | boolean | ImageValueType | RowData[] | string[] | null,
    richTextLanguage?: string,
    neededTranslation?: boolean
  ) => void;
  blockDetailsAllItems?: BlockDetailsFieldType[];
  setHoverFieldValues?: (field: BlockDetailsFieldType) => void;
  onPaletteIconClick?: (fields: BlockDetailsFieldType[], name: string) => void;
  expandParentOnDropdownShowOptions?: boolean;
}> = ({ item, onChangeValue, blockDetailsAllItems, setHoverFieldValues, onPaletteIconClick, expandParentOnDropdownShowOptions }) => {
  const { language } = useContext(ManageBlockDetailContext);

  const renderInputFields = (_item: BlockDetailsFieldType) => {
    const { slug, type, name, isStyle, value, values, isSearchable, valuesText } = _item;
    if (isStyle) return null;

    switch (type) {
      case "input":
        return <li key={slug}>{getInputField(_item, onChangeValue, language)}</li>;
      case "dropdown":
        return (
          <li key={slug}>
            {values && values.length > 0 ? (
              <Dropdown
                options={values as OptionType[]}
                onOptionSelect={(option) => onChangeValue(slug, option.key)}
                selectedOption={(values! as OptionType[]).find(({ key }) => key === value) ?? null}
                label={name}
                isSearchable={isSearchable}
                expandParentOnShowOptions={expandParentOnDropdownShowOptions}
              />
            ) : (
              <p className="font-light text-sm">{valuesText}</p>
            )}
          </li>
        );
      case "dropdownObj":
      case "dropdownAPI":
        return (
          <li key={slug}>
            <DropdownObj
              field={_item}
              onChangeValue={onChangeValue}
              blockDetailsAllItems={blockDetailsAllItems ?? []}
              onPaletteIconClick={onPaletteIconClick!}
              setHoverFieldValues={setHoverFieldValues}
            />
          </li>
        );
      case "multiple_input":
        return (
          <li key={slug}>
            <MultiValues
              label={name}
              slug={slug}
              values={value as string[]}
              onChangeValue={(slug, _value) => onChangeValue(slug, _value, undefined, false)}
            />
          </li>
        );
      case "multiCheckbox":
        return (
          <li key={slug}>
            {values && values.length > 0 ? (
              <MultiCheckbox
                slug={slug}
                value={value as string[]}
                values={values as OptionType[]}
                onChangeValue={(slug, newValue) => onChangeValue(slug, newValue)}
              />
            ) : (
              <p className="font-light text-sm">{valuesText}</p>
            )}
          </li>
        );
      case "richText":
        return (
          <li key={slug}>
            <RichTextInput
              data={value as LangValuePairType[]}
              setData={(data, richTextLanguage) => onChangeValue(slug, data, richTextLanguage, true)}
              label={name}
              language={language}
            />
          </li>
        );
      case "image":
      case "file":
        return (
          <li key={slug}>
            <FileInput
              label={name}
              value={value ? (value as ImageValueType) : null}
              onFileUpload={(_value) => onChangeValue(slug, _value)}
              onTrashClick={() => onChangeValue(slug, null)}
              type={type === "file" ? ".pdf" : undefined}
            />
          </li>
        );
      case "table":
        return (
          <li key={slug}>
            <CmsTable
              value={(value as LangValuePairType[]).find(({ lang }) => lang === language)?.value as RowData[]}
              onValueChanged={(_value) => onChangeValue(slug, _value, undefined, true)}
            />
          </li>
        );
      case "date":
        return (
          <li key={slug} className="justify-self-start">
            <DateInput
              resetIsEnable
              inputValidation={() => {}}
              defaultDate={new Date(value as string)}
              placeHolder="dd.mm.yyyy"
              onDateChanged={(date) => onChangeValue(slug, dayjs(date).format("YYYY-MM-DD"))}
            />
          </li>
        );
      case "read_only":
        return (
          <li key={slug} className="text-sm font-light">
            Type: <span className="font-normal">{value?.toString()}</span>
          </li>
        );
      case "component":
        const switchField = _item.fields?.find(({ type }) => type === "switch");
        return (
          <li key={slug}>
            <BlockDetailsSectionContainer
              key={_item.slug}
              title={_item.name}
              headerActions={
                _item.fields && _item.fields.filter(({ isStyle }) => isStyle).length > 0
                  ? [
                      {
                        icon: faPalette,
                        onClick: () => onPaletteIconClick && onPaletteIconClick(_item.fields!.filter(({ isStyle }) => isStyle), _item.name),
                      },
                    ]
                  : []
              }
              switchableActionIndex={0}
              switchValue={switchField ? (switchField.value as boolean) : undefined}
              setSwitchValue={
                switchField
                  ? (value) => {
                      if (value && _item.name === "Hover") {
                        if (setHoverFieldValues) setHoverFieldValues(_item);
                      } else {
                        onChangeValue(switchField.slug, value);
                      }
                    }
                  : undefined
              }
              collapsible={switchField !== undefined}
              collapsed={(switchField?.value as boolean) === false}
              nested
            >
              <BlockDetailsSection
                item={_item}
                onChangeValue={onChangeValue}
                setHoverFieldValues={setHoverFieldValues}
                expandParentOnDropdownShowOptions={switchField !== undefined}
                onPaletteIconClick={onPaletteIconClick}
              />
            </BlockDetailsSectionContainer>
          </li>
        );
    }
  };

  return (
    <ul className="grid grid-cols-1 gap-4">
      {item.fields ? item.fields.filter(({ excluded }) => !excluded).map((_item) => renderInputFields(_item)) : renderInputFields(item)}
    </ul>
  );
};

export default BlockDetailsSection;
